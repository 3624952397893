import {
  createSignal,
  For,
  Show,
  DEV,
  onMount,
  createUniqueId,
  createEffect,
} from "solid-js";
import type {
  InputOption,
  SelectProps,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import IconExpandMore from "~/img/icons/expand_more.svg";
import { createStore } from "solid-js/store";

import IconClose from "~/img/icons/close.svg";
import { useFormContext } from "~/contexts/FormContext";

/**
 * A Select field with the option to have multiple values selected.
 *
 * So even multiple is false, the value is an array.
 *
 * @param props SelectProps
 */
export function Select(props: SelectProps) {
  const [, { setValue: formSetValue, unsetValue }] = useFormContext();
  const setValue = props.defaultFormStorage
    ? props.defaultFormStorage
    : formSetValue;

  const isRequired = () => props.required || false;
  // eslint-disable-next-line solid/reactivity
  const id = `${props.name}-${createUniqueId()}`;

  let input: HTMLInputElement | undefined;

  const [activeOptions, setActiveOptions] = createStore<InputOption[]>([]);

  // eslint-disable-next-line solid/reactivity
  const [inputHasContent, setInputHasContent] = createSignal(!!props.value);
  const [inputIsFocus, setInputIsFocus] = createSignal(false);
  const [touched, setTouched] = createSignal(false);
  const [blurCount, setBlurCount] = createSignal(0);

  const [menuDialogIsActive, setMenuDialogIsActive] = createSignal(false);

  onMount(() => {
    props.validateFn && props.value && props.validateFn(props.value);
    setInputHasContent(!!props.value);
  });

  // If the value is changed from outside, we need to update the active options.
  createEffect(() => {
    if (props.options) {
      setActiveOptions([]);
      setInputHasContent(false);
      setInputIsFocus(false);
    }
  });

  createEffect(() => {
    // We have selected options, set the value for the form
    if (activeOptions.length > 0) {
      setValue!(props.name, activeOptions.map((o) => o.value).join(","));
      setInputHasContent(true);
      setInputIsFocus(false);
    } else if (!touched() && props.value && !props.multiple) {
      // Values are drilled down from the form, we need to set the active options
      const opt = props.options.find((o) => o.value === props.value);

      setActiveOptions([opt!]);
      setInputHasContent(true);
      setInputIsFocus(false);
    } else if (!touched() && props.value && props.multiple) {
      // Values are drilled down from the form, we need to set the active options
      const values: string[] = props.value.split(",");
      const options = props.options.filter((o) => values.includes(o.value));
      setActiveOptions(options);
      setInputHasContent(true);
      setInputIsFocus(false);
    } else {
      // No value, unset the value
      if (props.defaultFormStorage) {
        setValue!(props.name, "");
      } else {
        unsetValue!(props.name);
      }
      setInputHasContent(false);
      setInputIsFocus(false);
    }

    /* if (typeof props.onChange === "function" && activeOptions.length > 0) {
      props.onChange(activeOptions as InputOption[]);
    } */
  });

  function setActiveOptionFromUI(option: InputOption) {
    DEV && console.group("Select setActiveOptionFromUI");
    DEV && console.log("Option clicked", option);

    if (!props.multiple) {
      setActiveOptions([option]);
      setMenuDialogIsActive(false);
    } else {
      // Remove option if already in the selected set
      if (activeOptions.find((o) => o.value === option.value)) {
        // Remove the active option and its children.
        const isChild = (parent: InputOption, child: InputOption) => {
          return parent.children?.find((c) => c.value === child.value);
        };

        // Remove the active option and its children, and if child remove the parent as well.
        const newOptions = activeOptions.filter(
          (o) =>
            o.value !== option.value &&
            !isChild(option, o) &&
            !isChild(o, option),
        );

        setActiveOptions(newOptions);
      } else {
        // Otherwise add it.
        setActiveOptions((opts: InputOption[]) => [
          // We use a Set because we don't want to have duplicates.
          ...new Set([...opts, option, ...(option.children ?? [])]),
        ]);
      }
    }

    // Now that we allowed external hooks to be executed, we can check for disabled options.
    setActiveOptions((opts: InputOption[]) => [
      // We use a Set because we don't want to have duplicates.
      ...new Set([...opts.filter((o) => !o.disabled)]),
    ]);

    if (props.validateFn && touched()) {
      const value = activeOptions.map((o) => o.value).join(",");
      DEV && console.log("Validates value `%s`", value);
      props.validateFn(value);
    }
    DEV && console.groupEnd();

    props.onChange && props.onChange(option);
    // Fire the change event, which makes the form trigger the async request if any
    input!.dispatchEvent(new Event("change", { bubbles: true }));
  }

  const optionsList = (options: InputOption[], level = 0) => (
    <>
      <For each={options}>
        {(option) => (
          <>
            <li
              data-test={option.name}
              onClick={(e) => {
                if (!option.disabled) {
                  setActiveOptionFromUI(option);
                }
                e.stopPropagation();
              }}
              class={`level-${level}`}
              classList={{
                active:
                  activeOptions?.find((o) => o && o.value === option.value) &&
                  !option.disabled,
                disabled: option.disabled,
              }}
            >
              <Show when={props.multiple} fallback={option.label} keyed>
                <div class="checkbox-control">
                  <div class="form-slot form-checkbox-field">
                    <input
                      type="checkbox"
                      checked={
                        activeOptions?.find((o) => o.value === option.value) &&
                        !option.disabled
                      }
                      disabled={option.disabled}
                    />
                    <label>{option.label}</label>
                  </div>
                </div>
              </Show>
            </li>
            {/* Recursion */}
            <Show when={option.children}>
              {optionsList(option.children!, level + 1)}
            </Show>
          </>
        )}
      </For>
    </>
  );

  return (
    <>
      <div
        class={`form-control form-control-${props.name}`}
        classList={{
          "is-focus": inputIsFocus(),
          "has-content": inputHasContent(),
          "in-error": props["aria-invalid"],
          "is-disabled": props.disabled,
          "is-clearable": props.clearable,
        }}
      >
        <div
          class="form-slot form-select"
          classList={{
            "with-prepend-icon": props.prependIcon ? true : false,
          }}
        >
          <Show when={"prependIcon" in props} keyed>
            <i aria-hidden="true" class="cog-icon prepend-icon">
              {props.prependIcon}
            </i>
          </Show>
          <Show when={props.label} keyed>
            <label for={`${id}-label`}>
              {props.label}
              <Show when={isRequired()} keyed>
                {" "}
                *
              </Show>
            </label>
          </Show>
          <input
            type="text"
            id={`${id}-label`}
            data-test={`${props.name}-label`}
            required={isRequired()}
            value={activeOptions?.map((o) => o.label).join(", ") ?? null}
            aria-required={isRequired()}
            aria-invalid={props["aria-invalid"] || false}
            aria-errormessage={
              props["aria-invalid"] ? props["aria-errormessage"] : undefined
            }
            readonly
            onFocus={(event) => {
              !touched() && setTouched(true);
              props.onFocus && props.onFocus(event);
            }}
            onFocusIn={() => {
              setInputIsFocus(true);
              setMenuDialogIsActive(true);
            }}
            onBlur={(event) => {
              setBlurCount((prev) => prev + 1);
              if (props.onBlur) {
                props.onBlur(event);
              }
              if (props.validateFn && touched() && blurCount() > 0) {
                props.validateFn(event.currentTarget.value);
              }
              setInputIsFocus(false);
            }}
          />{" "}
          <Show when={props.clearable} keyed>
            <button
              type="button"
              onClick={() => {
                setActiveOptions([]);
                // Fire the change event, which makes the form trigger the async request if any
                input!.dispatchEvent(new Event("change", { bubbles: true }));
              }}
            >
              <IconClose />
            </button>
          </Show>
          <i aria-hidden="true" class="cog-icon">
            <IconExpandMore />
          </i>
          <input
            ref={input}
            type="hidden"
            id={id}
            name={props.name}
            data-test={props.name}
            value={activeOptions?.map((o) => o.value).join(",") ?? null}
            required={isRequired()}
            aria-required={isRequired()}
          />
          <div class="menu-dialog" classList={{ active: menuDialogIsActive() }}>
            <div
              class="dialog-overlay"
              onClick={() => setMenuDialogIsActive(false)}
            />
            <ul class="list">{optionsList(props.options)}</ul>
          </div>
        </div>
        <Show when={props.help} keyed>
          <div class="field-help">{props.help}</div>
        </Show>
        <Show when={import.meta.env.VITE_KB_DEBUG_FORMS === "1"} keyed>
          <div class="field-debug">
            {`Active label: ${activeOptions.map((o) => o.label).join(", ")}`}
            <br />
            {`Active value: ${activeOptions.map((o) => o.value).join(",")}`}
          </div>
        </Show>
      </div>
    </>
  );
}
